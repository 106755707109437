<template>
  <div
    class="actions flex flex-row place-items-center gap-4 m-auto transition-all duration-200"
  >
    <UnityLoginLinks v-if="!isAuthenticated" class="login button">
      <template #login>
        {{ t('Log in to read message') }}
      </template>
    </UnityLoginLinks>
    <LinkTo
      v-if="showSubscribeLink"
      :to="subscribeUrl"
      class="subscribe button"
    >
      {{ t('Subscribe to Daily word') }}
    </LinkTo>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    hideSubscribeLinkWhenNotAuthenticated: boolean
  }>(),
  {
    hideSubscribeLinkWhenNotAuthenticated: false,
  },
)
const { dailyWordSubscribeUrl } = useAppConfig()
const { t, locale } = useI18n()
const { data, isAuthenticated } = useLoginLinks()

const showSubscribeLink = computed(() => {
  return !props.hideSubscribeLinkWhenNotAuthenticated || isAuthenticated.value
})

const subscribeUrl = computed(
  () => dailyWordSubscribeUrl[unref(locale) as 'en' | 'es'],
)
</script>

<i18n lang="json">
{
  "en": {
    "Log in to read message": "Log in to read message",
    "Subscribe to Daily word": "Subscribe to Daily word"
  },
  "es": {
    "Log in to read message": "Iniciar sesión para leer el mensaje",
    "Subscribe to Daily word": "Suscríbete a La Palabra Diaria"
  }
}
</i18n>
